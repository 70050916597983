import { CreateControllerFn } from '@wix/yoshi-flow-editor';
import { MEMBERS_AREA } from '@wix/app-definition-ids';
import { createNavigator } from '../../services/navigation';
import { ModalType, Status } from '../../consts/collectionsConsts';
import { getBaseUrl } from '../../common/utils';
import { getActions } from '../../viewerScriptHelpers/actions';
import type GalleryWixCodeApiManager from '../../common/WixCode/GalleryWixCodeApiManager';
import type DataFetcher from '../../viewerScriptHelpers/DataFetcher';
import type AppStateManager from '../../viewerScriptHelpers/AppStateManager';
import type CommentsManager from '../../common/CommentsManager';
import { defaultList } from '../../viewerScriptHelpers/defaultData';

const createController: CreateControllerFn = async (props: any) => {
  const { flowAPI, controllerConfig, appData } = props;
  const {
    galleryWixCodeApiManager,
    dataFetcher,
    commentsManager,
    appStateManager,
  } = appData as {
    context: any;
    galleryWixCodeApiManager: GalleryWixCodeApiManager;
    dataFetcher: DataFetcher;
    commentsManager: CommentsManager;
    appStateManager: AppStateManager;
  };
  const {
    environment,
    translations: { t },
  } = flowAPI;
  const isViewer = environment.isViewer || environment.isSSR;
  const { setProps, appParams, platformAPIs, $w } = flowAPI.controllerConfig;
  const { type, wixCodeApi } = controllerConfig;
  const { baseUrls = {}, appDefinitionId, instance, instanceId } = appParams;

  const getAuthHeader = () => wixCodeApi.site.getAppToken(appDefinitionId);

  const baseUrl = getBaseUrl(isViewer);
  const isSSR = environment.isSSR;
  const isTemplate = environment.isEditor;
  const biLogger = flowAPI.bi;
  const collectionId =
    wixCodeApi.location.path.length > 1 && !isTemplate
      ? wixCodeApi.location.path[1]
      : '';

  const user = {
    role: wixCodeApi.user.currentUser?.role,
    id: wixCodeApi.user.currentUser?.id,
  };

  const changeGalleryVisiblity = (visibility: boolean) => {
    platformAPIs.pubSub.publish(
      'changeGalleryVisibility',
      { visible: visibility },
      false,
    );
  };

  const triggerJoinModal = async () => {
    setProps({
      actionCallbacks: [
        { callbackName: 'showModal', args: { type: ModalType.JOIN } },
      ] as Callbacks,
    });
  };

  const albumData = await dataFetcher.getAlbumData({
    baseUrl,
  });

  let initialCollection: MediaCollection;

  if (collectionId) {
    const [getCollectionResponse, getCollectionItemsResponse] =
      await Promise.all([
        dataFetcher.getCollectionData({ collectionId, baseUrl }),
        dataFetcher.getCollectionItems({
          collectionId,
          baseUrl,
        }),
      ]);

    appStateManager.state.user.status =
      getCollectionResponse.memberStatus || Status.NOT_MEMBER;

    const mediaCollection = getCollectionResponse.mediaCollection;
    mediaCollection.items = getCollectionItemsResponse;
    if (mediaCollection) {
      initialCollection = mediaCollection;
      appStateManager.state.collection = initialCollection;
    }
  } else {
    if (flowAPI.environment.isEditor) {
      initialCollection = defaultList.mediaCollection;
    } else {
      return {
        pageReady: () => {},
      };
    }
  }

  appStateManager.registerWidget({
    name: 'collection',
    setProps,
  });

  return {
    pageReady: async () => {
      if (!isSSR && !albumData) {
        return;
      }
      if (!isSSR) {
        commentsManager.initCommentsController({
          controllerConfig,
          albumData,
          biLogger,
          collectionId,
          wixCodeApi,
          appStateManager,
          commentsManager,
          isMainController: true,
          httpClient: flowAPI.httpClient,
        });
      }

      const actions = getActions({
        getAuthHeader,
        albumData,
        appStateManager,
        setProps,
        errorMonitor: flowAPI.errorMonitor,
        httpClient: flowAPI.httpClient,
      });

      platformAPIs.pubSub.subscribe('triggerJoinModal', triggerJoinModal);
      platformAPIs.pubSub.subscribe('triggerSignUpModal', () =>
        actions.JOIN(JSON.stringify({ origin: 'remove image' })),
      );

      wixCodeApi.user.onLogin(() => {
        appStateManager.refetchCollectionData();
      });

      setProps({
        albumData,
        collectionsWixCodeApi: {
          setItems: (items: any[]) => {
            galleryWixCodeApiManager.setItems(items);
          },
          setSettings: (settings: any) => {
            const galleryWixCodeActions =
              galleryWixCodeApiManager.galleryWixCodeActions;
            galleryWixCodeActions &&
              galleryWixCodeActions.setSettings(settings);
          },
          setStyleParams: (styleParams: any) => {
            const galleryWixCodeActions =
              galleryWixCodeApiManager.galleryWixCodeActions;
            galleryWixCodeActions &&
              galleryWixCodeActions.setStyleParams({
                ...styleParams,
                allowDownload: albumData.settings.styleParams.allowDownload,
                allowSocial: albumData.settings.styleParams.allowSocial,
                loveButton: false,
              });
          },
        },
        navigator: createNavigator(wixCodeApi),
        collection: initialCollection,
        instanceId,
        galleryActions: {
          showGallery: () => {
            changeGalleryVisiblity(true);
          },
          hideGallery: () => {
            changeGalleryVisiblity(false);
          },
        },
        user,
        navigateToMember: (memberId: string, memberSlug: string) => {
          wixCodeApi.site
            .getPublicAPI(MEMBERS_AREA)
            .then((api: any) => {
              api.navigateToMember({ memberId, memberSlug });
            })
            .catch((e: Error) => {
              console.error('Navigate to profile: FAIL', e);
            });
        },
        refetchMembers: appStateManager.fetchMembers,
        queryParams: wixCodeApi.location.query,
        userStatus: appStateManager.getUserStatus(),
        loggedIn: wixCodeApi.user.currentUser.loggedIn,
        triggerJoinModal,
        actions,
      });
    },
  };
};

export default createController;
